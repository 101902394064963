const people = [
  {
    name: 'Aneta Majak',
    job: 'Organizational Coordinator',
    photo1: require('./images/Aneta_1_min.jpg'),
    photo2: require('./images/Aneta_2_min.jpg'),
    photoFull: require('./images/Aneta_2.jpg'),
    video: require('./videos/Aneta.mp4'),
    description:
      'Organizational Coordinator. She could create a wallpaper for a decent-sized room with the amount of paperwork that went through her hands. If you could take a look on her phone billings, the list would be as long as Szczepkowski’s list of achievements. She also takes care of acquiring sponsors and patrons for the museum.',
    mail: 'a.majak@mckmilanowek.pl',
    domain: {
      url: 'https://mckmilanowek.pl',
      name: 'mckmilanowek.pl',
    },
    linkedin: '',
  },
  {
    name: 'Tobiasz Bułynko',
    job: 'Artistic coordinator',
    photo1: require('./images/Tobiasz_1_min.jpg'),
    photo2: require('./images/Tobiasz_2_min.jpg'),
    photoFull: require('./images/Tobiasz_2.jpg'),
    video: require('./videos/Tobiasz.mp4'),
    description:
      'He is almost first on our list based on alphabetical order. However, he is definitely “the Number One” of the whole project. Just as Jan Szczepkowski led the Council of Central Institute of Culture, he pilots everything and everyone! He came up with the vision for this museum, he IS the beginning of the whole shebang. Can you believe he does not have a Facebook account?',
    mail: 'tobiasz.bulynko@gmail.com',
    domain: '',
    linkedin: '',
  },
  {
    name: 'Karolina Polkowska',
    job: '3D specialist',
    photo1: require('./images/Karo_1_min.jpg'),
    photo2: require('./images/Karo_2_min.jpg'),
    photoFull: require('./images/Karo_2.jpg'),
    video: require('./videos/Karo.mp4'),
    description:
      'Thanks to this lovely lady everything seems to be spinning-around. Figuratively and literally as she managed to recreate Szczepkowski’s sculptures in 3D. We are convinced she used some sort of magic ritual to give them life or at least some good spell. Especially that even the reliefs seem so alive, they’re ready to leave the screen.',
    mail: 'karolpolko@gmail.com',
    domain: {
      url: 'http://polkowskart.pl',
      name: 'polkowskart.pl',
    },
    linkedin: '',
  },
  {
    name: 'Michał Słowiński',
    job: 'Text editor',
    photo1: require('./images/Michal_1_min.jpg'),
    photo2: require('./images/Michal_2_min.jpg'),
    photoFull: require('./images/Michal_2.jpg'),
    video: require('./videos/Michal.mp4'),
    description:
      'All the original text that the website contains, was more or less written on his trusty laptop. Rumor says he likes his job and takes his pride in it. If he could choose his favourite Szczepkowski’s work it would probably be the Bullock (1909), as he has written about this particular piece. He has done a huge research on our artist which apparently made him happy. Bless. Him.',
    mail: 'm.slowinski@mckmilanowek.pl',
    domain: {
      url: 'https://mckmilanowek.pl',
      name: 'mckmilanowek.pl',
    },
    linkedin: '',
  },
  {
    name: 'Kuba Duda',
    job: 'Programmer',
    photo1: require('./images/Kuba_1_min.jpg'),
    photo2: require('./images/Kuba_2_min.jpg'),
    photoFull: require('./images/Kuba_2.jpg'),
    video: require('./videos/Kuba.mp4'),
    description:
      'Meet our Website Builder. He is as focused on his work as Stanisław Moniuszko on a pedestal in front of the Grand Theater. We don’t know if he plays any instrument, though the way he programs is like he is writing sonatas.',
    mail: 'kubakoduje@gmail.com',
    domain: {
      url: 'https://kubakoduje.pl',
      name: 'kubakoduje.pl',
    },
    linkedin: 'linkedin.com/in/kuba-duda/',
  },
  {
    name: 'Mateusz Orłowski',
    job: 'Programmer',
    photo1: require('./images/Mateusz_1_min.jpg'),
    photo2: require('./images/Mateusz_2.jpg'),
    photoFull: require('./images/Mateusz_2.jpg'),
    video: require('./videos/Mateusz.mp4'),
    description:
      'He builds the website along with Kuba, but he took an interesting approach to it: he does not use a mouse. Not because he does not own one, he just chooses to work without it. The whole process proves to be as laborious as making a mock-up of the triumphal arch of Heroes of Warsaw on Na Rozdrożu Square. We call him Mati. Well…okay. We don’t.',
    mail: 'mateusz.orlowski95@gmail.com',
    domain: '',
    linkedin: 'linkedin.com/in/mateusz-orłowski/',
  },
  {
    name: 'Ola Andrzejczak',
    job: 'Translator',
    photo1: require('./images/Ola_1_min.jpg'),
    photo2: require('./images/Ola_2_min.jpg'),
    photoFull: require('./images/Ola_2.jpg'),
    video: require('./videos/Ola.mp4'),
    description:
      'So my friend Michał wrote a very funny pun here in Polish version using two of numerous meanings of the word “tłumaczyć” and combined Polish and English words making a hilarious description. My point is: I could technically translate it but it’s not gonna be as funny. To compensate y’all here’s a random pun in English for ya that I found funny: “What do you call a dog that can do magic? A Labracadabrador!” Anyways, hi! I am Ola. I have translated the website.',
    mail: 'aleksandra.katarzyna.andrzejczak@gmail.com',
    domain: '',
    linkedin: '',
  },
  {
    name: 'Joshua Szpilarski',
    job: 'Lector',
    photo1: require('./images/Josh_1_min.jpg'),
    photo2: require('./images/Josh_2_min.jpg'),
    photoFull: require('./images/Josh_2.jpg'),
    video: require('./videos/Josh.mp4'),
    description:
      'He is the voice of the project. The joke is that his name is indeed Josh, but he reads Polish with perfect diction though he knows his stuff about foreign languages as well. Seriously, awaken in the middle of the night he will pronounce “Kriegsgräberabteilung” without even the tiniest stutter!',
    mail: '',
    domain: '',
    linkedin: '',
  },
];

export default people;
