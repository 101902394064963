import {Link} from 'gatsby';
import styled, {keyframes} from 'styled-components';
import bg from '../bg.png';

export const PageContainer = styled.div`
  width: 100%;
  background-color: ${({backgroundColor}) => backgroundColor};
  padding: 5rem 0;
  margin-top: -12px;
  position: relative;

  .second-parallax {
    background-image: url(${bg});
    background-position: bottom;
    background-size: 60%;
    background-repeat: no-repeat;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
  position: relative;

  @media (max-width: 1024px) {
    padding: 0 40px;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
`;

export const ContainerCreators = styled.div`
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
  position: relative;

  @media (max-width: 1024px) {
    padding: 0 40px;
  }
`;

export const ContainerPerson = styled.div`
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;

  @media (max-width: 969px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const PersonLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;

  @media (max-width: 969px) {
    min-width: 20rem;
    width: 80%;
    margin-top: 2rem;
  }
`;

export const PersonRightColumn = styled.div`
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 20rem;
  }
  video {
    width: 20rem;
  }
`;

export const PersonName = styled.p`
  color: #d5d5d5;
  font-family: 'LATOBLACK';
  font-size: 1rem;
  letter-spacing: 0.58px;
  line-height: normal;
`;

export const PersonJob = styled.p`
  color: #d5d5d5;
  font-family: 'LATOBLACK';
  line-height: normal;
  font-size: 0.8rem;
  letter-spacing: 0.33px;
  line-height: 21.71px;
`;

export const PersonDescription = styled.p`
  color: #d5d5d5;
  font-family: 'LATOREGULAR';
  line-height: normal;
  font-size: 0.8rem;
  letter-spacing: 0.33px;
  line-height: 1.5;
  margin: 1rem 0;
  text-align: justify;
`;

export const CloseButton = styled.img`
  width: 1.5rem;
  position: absolute;
  top: 0;
  right: -1rem;
  cursor: pointer;

  @media (max-width: 969px) {
    right: 0.5rem;
  }
  @media (max-width: 360px) {
    top: -2rem;
  }
`;

export const FlexLine = styled.a`
  display: flex;
  align-items: center;
  margin: 0.3rem 0;
  text-decoration: none;
`;

export const SocialIcon = styled.img`
  width: 2.5rem;
  padding-right: 0.7rem;
`;

export const SectionText = styled.p`
  color: #ffffff;
  font-family: 'LATOREGULAR';
  font-size: 0.83rem;
  font-weight: 400;
  margin-bottom: 2rem;
  line-height: 1.8;
  text-align: justify;
`;

export const CreatorsViewText = styled.p`
  color: #d5d5d5;
  font-family: 'LATOREGULAR';
  fontsize: '0.83rem';
  font-weight: 400;
  line-height: 1.8;
  text-align: justify;
`;

export const TitlePage = styled.h3`
  color: #525252;
  font-family: 'RENNERBLACK';
  font-size: 4rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 328.44px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 1px;
  white-space: nowrap;
  transform: translateX(-50%) translateY(-80%);
  line-height: 1;

  @media (max-width: 700px) {
    font-size: 3.2rem;
  }

  @media (max-width: 490px) {
    font-size: 2.8rem;
  }

  @media (max-width: 400px) {
    font-size: 2.3rem;
  }
`;

export const TitlePageEN = styled.h3`
  color: #525252;
  font-family: 'RENNERBLACK';
  font-size: 4rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 328.44px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 1px;
  white-space: nowrap;
  transform: translateX(-50%) translateY(-80%);
  line-height: 1;

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 510px) {
    font-size: 2.5rem;
  }
  @media (max-width: 410px) {
    font-size: 2rem;
  }
`;

export const ColorWrapper = styled.div`
  background-color: ${({bgColor}) => bgColor};
  padding: 3rem 0;
  position: relative;
`;

export const ImageWrapper = styled.div`
  background-image: url(${({srcBg}) => srcBg});
  background-size: cover;
  width: 100%;
  height: 62vh;
  margin-top: calc(-5rem - 12px);
  background-position: 75% top;
`;

export const CreatorBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
`;

export const CreatorView = styled.div`
  width: 100%;
  height: 15rem;
  background: url(${({src}) => src});
  background-size: cover;
  background-position: center top;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: 0.5s ease-in-out;

  &:hover {
    background-image: url(${({src2}) => src2});
  }
`;

export const CreatorName = styled.p`
  color: #d5d5d5;
  font-family: 'LATOBLACK';
  text-align: center;
  font-size: 0.8rem;
  letter-spacing: 0.58px;
  line-height: normal;
`;

export const CreatorJob = styled.p`
  color: #d5d5d5;
  font-family: 'LATOBLACK';
  text-align: center;
  line-height: normal;
  font-size: 0.5rem;
  letter-spacing: 0.33px;
  line-height: 21.71px;
`;
