import React, {useState} from 'react';
import {
  ColorWrapper,
  Container,
  PageContainer,
  ImageWrapper,
  CreatorBlock,
  CreatorView,
  CreatorName,
  CreatorJob,
  ContainerPerson,
  PersonLeftColumn,
  PersonRightColumn,
  PersonName,
  PersonJob,
  PersonDescription,
  FlexLine,
  SocialIcon,
  SectionText,
  CloseButton,
  TitlePageEN,
  CreatorsViewText,
} from './styles';
import {Parallax} from 'react-scroll-parallax';
import bg from './images/background.jpg';
import IconMail from '../../../images/mail2.svg';
import IconWeb from '../../../images/www.svg';
import IconClose from '../../../images/close.svg';
import people from './peopleEN.js';
import IconIn from '../../../images/lidkedin.svg';

const PersonView = ({person, setSelectedPerson}) => {
  return (
    <ContainerPerson>
      <CloseButton src={IconClose} onClick={() => setSelectedPerson(null)} />
      <PersonLeftColumn>
        <PersonName>{person.name}</PersonName>
        <PersonJob>{person.job}</PersonJob>
        <PersonDescription>{person.description}</PersonDescription>
        {person.mail && (
          <FlexLine href={`mailto:${person.mail}`} style={{marginTop: '1rem'}}>
            <SocialIcon src={IconMail} />
            <CreatorsViewText>{person.mail}</CreatorsViewText>
          </FlexLine>
        )}
        {person.linkedin && (
          <FlexLine
            href={`https://${person.linkedin}`}
            style={{marginTop: '1rem'}}>
            <SocialIcon src={IconIn} />
            <CreatorsViewText>{person.linkedin}</CreatorsViewText>
          </FlexLine>
        )}
        {person.domain && (
          <FlexLine href={person.domain.url} style={{marginTop: '1rem'}}>
            <SocialIcon src={IconWeb} />
            <CreatorsViewText>{person.domain.name}</CreatorsViewText>
          </FlexLine>
        )}
      </PersonLeftColumn>
      <PersonRightColumn>
        <video src={person.video} autoPlay controls={false} loop />
      </PersonRightColumn>
    </ContainerPerson>
  );
};

const CreatorsPage = () => {
  const [selectedPerson, setSelectedPerson] = useState(null);
  return (
    <PageContainer backgroundColor={'#e09087'} style={{paddingBottom: 0}}>
      <Parallax y={[20, -20]}>
        <ImageWrapper srcBg={bg} />
      </Parallax>
      <Parallax y={[8, -160]} className="second-parallax">
        <ColorWrapper bgColor="#525252">
          <TitlePageEN>MUSEUM CREATORS</TitlePageEN>
          {selectedPerson !== null ? (
            <PersonView
              person={people[selectedPerson]}
              setSelectedPerson={setSelectedPerson}
            />
          ) : (
            <>
              <Container style={{display: 'block'}}>
                <SectionText>
                  &emsp;&emsp;The Virtual Museum of Jan Szczepkowski is a
                  project created by people with a passion for culture. We know
                  that we are surrounded by vast heritage that must be saved
                  from oblivion. We are ready to carry out bold projects, to
                  conquer difficult tasks. Our project does not end with
                  launching the museum’s website. It’s just the beginning.
                  <br />
                  <br />
                  We invite you to cooperation.
                </SectionText>
              </Container>
              <Container>
                {people.map(({name, job, photo1, photo2}, i) => (
                  <CreatorBlock onClick={() => setSelectedPerson(i)}>
                    <CreatorView src={photo1} src2={photo2} />
                    <CreatorName>{name}</CreatorName>
                    <CreatorJob>{job}</CreatorJob>
                  </CreatorBlock>
                ))}
              </Container>
            </>
          )}
        </ColorWrapper>
      </Parallax>
    </PageContainer>
  );
};

export default CreatorsPage;
