import React from 'react';
import SEO from '../../components/seo';
import CreatorsPageEN from '../../components/desktop/CreatorsPage/CreatorsPageEN';

const About = () => {
  return (
    <>
      <SEO title={'Museum creators'} />
      <CreatorsPageEN />
    </>
  );
};

export default About;
